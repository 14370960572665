<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">優惠券資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12">
              <form-button-upload :fileValue.sync="formData.image" label="圖片" ref="uploadImage" inputRef="imgFile" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="優惠券名稱" :fieldValue.sync="formData.name" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$statusOptions" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-select label="優惠類型" :fieldValue.sync="formData.discount_type" :options="typeOptions" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-input
                :label="couponDisplay"
                :fieldValue.sync="formData.discount_value"
                required
                type="number"
                isIntegerOnly
                :customRules="[
                  v => v > 0 || '優惠必須大於0',
                  v => {
                    return formData.discount_type === 'percentage' && this.$validate.regexNumber(v)
                      ? parseInt(v) <= 100 || '百分比不大於100'
                      : true
                  },
                ]"
                :suffix="formData.discount_type === 'percentage' ? '% off' : ''"
                :prefix="formData.discount_type === 'price' ? '- $' : ''"
                :disabled="!formData.discount_type"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-select
                label="一次性／可重用"
                :fieldValue.sync="formData.reusable"
                required
                :options="reusableOptions"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-date-picker label="使用期限" :fieldValue.sync="formData.dateRange" isDateRange required />
            </v-col>

            <v-col cols="12" md="6">
              <form-input
                label="優惠碼（如不填寫，系統會自動產生一個優惠碼）"
                :fieldValue.sync="formData.code"
                :readonly="$validate.DataValid(initData.code)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'

export default {
  name: 'CouponDetail',
  components: {
    FormInput,
    FormSelect,
    FormButtonUpload,
    FormDatePicker,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    couponDisplay() {
      let amount = '0'
      if (this.formData.discount_type === 'price') {
        if (this.formData.discount_value) {
          amount = this.formData.discount_value
        }
        return `價格優惠：減價 $${amount}`
      } else if (this.formData.discount_type === 'percentage') {
        if (this.formData.discount_value) {
          if (parseInt(this.formData.discount_value) >= 100) {
            amount = '10'
          } else {
            const d = 100 - parseFloat(this.formData.discount_value)
            if (d % 10 === 0) {
              amount = (d * 0.1).toFixed(0)
            } else {
              amount = (d * 0.1).toFixed(1)
            }
          }
        }
        return `百份比優惠：${amount}折`
      }

      return '優惠'
    },
  },
  data: () => ({
    formData: {
      image: '',
      name: '',
      status: 'active',
      discount_type: null,
      discount_value: '',
      reusable: null,
      code: '',
      dateRange: [],
    },
    initData: {
      initData: '',
    },

    typeOptions: [
      { value: 'percentage', text: '百分比' },
      { value: 'price', text: '價格優惠' },
    ],
    reusableOptions: [
      { text: '一次性', value: false },
      { text: '可重用', value: true },
    ],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetCouponById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }

        if (data.start_date) {
          this.formData.dateRange.push(data.start_date);
          if (data.expiry_date) {
            this.formData.dateRange.push(data.expiry_date);
          }
        }

        if (data.code) {
          this.initData.code = data.code;
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'CouponList',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'CouponList',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        if (!this.formData.image) {
          this.$refs['uploadImage'].showError();
        }
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      let payload = this.$common.duplicateData(this.formData);
      delete payload["dateRange"];

      if (this.formData.dateRange.length > 0) {
        payload["start_date"] = this.formData.dateRange[0];
      }
      if (this.formData.dateRange.length > 1) {
        payload["expiry_date"] = this.formData.dateRange[1];
      }

      payload["discount_value"] = parseFloat(payload["discount_value"]);

      const user = this.getCurrentUserData()

      if (this.$validate.DataValid(this.id)) {
        delete payload["code"];
        try {
          await this.$Fetcher.UpdateCoupon(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewCoupon(payload, user.id, user.token)

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'CouponList',
          })
        } catch (err) {
          this.$common.error(err)
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
